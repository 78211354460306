import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import YourDetailsFooter from './YourDetailsFooter';
import BusinessDetailsSection from './../../components/Fieldsets/BusinessDetailsSection';
import AddressDetailsSection from './../../components/Fieldsets/AddressDetailsSection';
import ContactDetailsSection from './../../components/Fieldsets/ContactDetailsSection';
import Button from './../../components/Button/Button';
import ValidationPanel from './../../components/ValidationPanel/ValidationPanel';
import pick from 'lodash/pick';
import { useDispatch, useSelector } from 'react-redux';
import { handleReturnToPayroll } from '../../store/thunks/global';
import { saveParticipantDetails } from '../../store/thunks/participant';
import IconSpinner from '../../components/IconSpinner/IconSpinner';

const YourDetails = props => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [validationErrors, setValidationErrors] = useState(undefined);
  const [isSaving, setSaving] = useState(false);
  const {
    isBureau,
    loading,
    validations,
    participant,
    payrollName,
    redirectUrl
  } = useSelector(state => state.participant.data);
  const { nextEnabled } = useSelector(state => state.global.data);

  const navigateToNext = () => {
    // Navigate to next step
    props.history.push({
      pathname: '/payment-method',
      hash: props.location.hash
    });
  };

  const saveParticipant = event => {
    setSaving(true);
    let updatedParticipant = { ...participant, ...data };
    // Pass down a couple of attributes to the 1st employer when the participant is not a bureau,
    //  thus being the employer themselves
    if (!isBureau) {
      updatedParticipant = {
        ...updatedParticipant,
        employers: [
          {
            ...participant.employers[0],
            ...pick(data, ['businessIdentifier', 'organisationName', 'contact'])
          }
        ]
      };
    }

    dispatch(saveParticipantDetails(updatedParticipant));
  };

  const handleSectionChange = newData => {
    setData({ ...data, ...newData });
  };

  useEffect(() => {
    document.title = `Beam - Your details`;
  }, []);

  useEffect(
    () => {
      if (participant) {
        const propertyNames = [
          'address',
          'businessIdentifier',
          'contact',
          'organisationName'
        ];

        // Pick certain properties from participant for form pre-population
        const data = pick(participant, propertyNames);

        // only allow the user to go to the next page when there is no validation error
        // regardless of where the error is originated from
        nextEnabled &&
          (!validations || (validations && validations.length === 0)) &&
          navigateToNext();

        setSaving(false);
        setData(data);
        setValidationErrors(validations ? validations : []);
      }
    },
    [participant, validations, nextEnabled]
  );

  return (
    <div>
      {data &&
        participant &&
        validationErrors && (
          <section className="Section">
            <h2>Make sure your details are correct</h2>
            <form>
              {!nextEnabled &&
                validationErrors &&
                validationErrors.length > 0 && (
                  <ValidationPanel errors={validationErrors} />
                )}
              <BusinessDetailsSection
                businessIdentifier={data.businessIdentifier}
                organisationName={data.organisationName}
                validations={validationErrors.filter(
                  error =>
                    error.path.indexOf('businessIdentifier') > -1 ||
                    error.path.indexOf('organisationName') > -1
                )}
                onChange={handleSectionChange}
              />
              <AddressDetailsSection
                address={data.address}
                validations={validationErrors.filter(
                  error => error.path.indexOf('address') > -1
                )}
                onChange={handleSectionChange}
              />
              <ContactDetailsSection
                contact={data.contact}
                validations={validationErrors.filter(
                  error => error.path.indexOf('contact') > -1
                )}
                onChange={handleSectionChange}
              />
            </form>
            {nextEnabled &&
              validationErrors &&
              validationErrors.length > 0 && (
                <ValidationPanel errors={validationErrors} />
              )}
          </section>
        )}

      <section className="Section PageNavGroup">
        <Button
          type="button"
          primary
          hollow
          customclass="Button--cancel"
          onClick={() =>
            dispatch(handleReturnToPayroll(payrollName, redirectUrl))
          }
        >
          <span>Cancel</span>
        </Button>
        {isSaving && (
          <Button>
            <IconSpinner style={{ marginTop: '10px' }} />
          </Button>
        )}
        <Button
          type="submit"
          primary
          next
          customclass="Form-button"
          disabled={loading || isSaving}
          onClick={saveParticipant}
        >
          <span>Next</span>
        </Button>
      </section>

      <YourDetailsFooter />
    </div>
  );
};

export default withRouter(props => <YourDetails {...props} />);
