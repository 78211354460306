import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CollapsableError from './CollapsableError';
import './ValidationPanel.css';

class ValidationPanel extends Component {
  render() {
    const { errors } = this.props;

    // let's consolidate the errors
    const uniqueErrorCodes = [];
    const consolidatedErrors = [];

    errors.forEach(error => {
      if (!uniqueErrorCodes.includes(error.errorCode)) {
        // add the error code to the list
        uniqueErrorCodes.push(error.errorCode);
        // also add the data
        consolidatedErrors.push({
          errorCode: error.errorCode,
          message: error.message,
          paths: [error.path]
        });
      } else {
        // add path tho the collection
        consolidatedErrors.forEach(consolidatedError => {
          if (error.errorCode === consolidatedError.errorCode) {
            consolidatedError.paths.push(error.path);
          }
        });
      }
    });

    // sort the paths array in consolidatedErrors based on length of string
    consolidatedErrors.forEach(error =>
      error.paths.sort((a, b) => a.length - b.length)
    );

    return consolidatedErrors ? (
      <div className="ValidationPanel">
        <ul>
          {consolidatedErrors.map((error, index) => (
            <CollapsableError error={error} key={index} />
          ))}
        </ul>
      </div>
    ) : null;
  }
}

ValidationPanel.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ValidationPanel;
