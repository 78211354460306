import React, { useState } from 'react';
import './CollapsableError.css';

const CollapsableError = ({ error }) => {
  const [showPaths, setShowPaths] = useState(false);
  const toggleShowPaths = () => {
    setShowPaths(!showPaths);
  };
  return (
    <li className="CollapsableError">
      <span
        className={
          showPaths
            ? 'CollapsableError-title CollapsableError-collapsable'
            : 'CollapsableError-title CollapsableError-clickable'
        }
        onClick={toggleShowPaths}
      >
        {error.errorCode}: <strong>{error.message}</strong>
      </span>

      <ul className={showPaths ? '' : 'CollapsableError-path-hidden'}>
        {error.paths.map(path => (
          <li className="CollapsableError-path" key={path}>
            {path}
          </li>
        ))}
      </ul>
    </li>
  );
};

export default CollapsableError;
